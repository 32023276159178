import React from "react"
import {graphql, navigate} from "gatsby"

import Layout from "../components/layout"
import Meta from "../components/meta"

export default class BookADemo extends React.Component {
    constructor(props) {
        super();
        this.state = {
            name: '',
            last_name: '',
            company_name: '',
            email: (props.location && props.location.state && props.location.state.email) || '',
            approve: false,
        }

        if (this.state.email) {
            const fetchRequest = {
                method: 'POST',
                mode: 'no-cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: 'email=' + this.state.email
            };
            fetch('https://26ivp6dhdi.execute-api.us-east-1.amazonaws.com/default/sendContactForm', fetchRequest).then(data => {
                console.log('Success:', data);
            })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleChangeCheckbox = (e) => {
        this.setState({
            [e.target.name]: e.target.checked
        });
    }

    handleSubmit = (e) => {
        const {isFormSent} = this.state;
        if (isFormSent) {
            navigate('/thanks');
        }
    }

    render() {
        const {data, location} = this.props;
        const {name, last_name, company_name, email, approve} = this.state;
        return data && (
            <Layout location={location}>
                <Meta title="Book a Demo"/>
                <div className="request_demo">
                    <div className="contact_blog wrapper">
                        <div className="left_col">
                            <h2>JUST COMPLETE THESE FINAL DETAILS TO SET UP YOUR DEMO</h2>
                            <h3>( we won’t be able to process your request without them! )</h3>
                            <form className="myform validator" method="post" target="mailResponse"
                                  action="https://26ivp6dhdi.execute-api.us-east-1.amazonaws.com/default/sendContactForm"
                                  onSubmit={() => this.setState({isFormSent: true})}>
                                <div className="col in_half">
                                    <input type="text" placeholder="First Name" className="in" name="name" value={name}
                                           onChange={this.handleChange}/>
                                </div>
                                <div className="col in_half">
                                    <input type="text" placeholder="Last Name" className="in" name="last_name"
                                           value={last_name} onChange={this.handleChange}/>
                                </div>
                                <div className="col">
                                    <input type="text" placeholder="Company Name" className="in" name="company_name"
                                           value={company_name} onChange={this.handleChange}/>
                                </div>
                                <div className="col">
                                    <input type="email" placeholder="Work Email" className="in" name="email"
                                           value={email} onChange={this.handleChange}/>
                                </div>
                                <label htmlFor="checkbox1" className={approve ? 'active' : ''}>
                                    <input required={true} type="checkbox" id="checkbox1" value="checked yes"
                                           name="approve" checked={approve} onChange={this.handleChangeCheckbox}/> I
                                    agree to the privacy policy including to Enerjoy using my contact details to contact
                                    me for marketing purposes.
                                </label>

                                <input type="submit" className="sub" value="REQUEST A DEMO"/>
                            </form>
                            <iframe name="mailResponse" style={{display: 'none'}} onLoad={this.handleSubmit}/>
                        </div>
                        <div className="right_col">
                            <iframe src="https://www.youtube.com/embed/6fIVOfMNJOw"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen/>
                        </div>
                    </div>
                    <div className="card request_card">
                        <div className="wrapper">
                            <div className="col">
                                <h3>United State</h3>
                                <p>
                                    729 Ocean Parkway<br/> Brooklyn NY 1123<br/> <a
                                    href="mailto:viki@enerjoy.co">viki@enerjoy.co</a>
                                </p>
                            </div>
                            <div className="col">
                                <h3>Westren Europe</h3>
                                <p>
                                    Otto Brixi<br/> <a
                                    href="mailto:viki@enerjoy.co">viki@enerjoy.co</a></p>
                            </div>
                            <div className="col">
                                <h3>Latin America</h3>
                                <p>Yura <br/> <a
                                    href="mailto:viki@enerjoy.co">viki@enerjoy.co</a></p>
                            </div>
                            <div className="col">
                                <h3>India</h3>
                                <p><a
                                    href="mailto:viki@enerjoy.co">viki@enerjoy.co</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
};

export const fluidImage = graphql`
    fragment fluidImage on File {
        childImageSharp {
            fluid(quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
                ...GatsbyImageSharpFluidLimitPresentationSize
            }
        }
    }
`;

export const query = graphql`
    query {
        platformGraphic1: file(relativePath: { eq: "platform graphic 1.png" }) { ...fluidImage }
        platformGraphic2: file(relativePath: { eq: "platform graphic 2.png" }) { ...fluidImage }
        integrationSolutions: file(relativePath: { eq: "co_it.png" }) { ...fluidImage }
    }
`;